import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { avatarLogoPlaceholder as avatarDefault } from '@edx/brand';

function avatarDefaultLogo({ src }) {
  const avatar = src ? (
    <img src={src} alt="user profile" />
  ) : (
    <img src={avatarDefault} alt="brand profile" />
  );
  return (
    <div className="avatarDefault">
      {avatar}
    </div>
  );
}

avatarDefaultLogo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default avatarDefaultLogo;
