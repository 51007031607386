import React from 'react';
import PropTypes from 'prop-types';

const NavLinks = ({ footerLinks }) => (
  <nav>
    <ul className="footer-nav-list">
      {footerLinks.map(({ title, href, target_blank: targetBlank }) => (
        <li key={title} className="footer-nav-list__item">
          <a
            href={href}
            className="footer-nav-list__link"
            target={targetBlank ? '_blank' : '_self'}
          >
            {title}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

NavLinks.propTypes = {
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    target_blank: PropTypes.bool.isRequired,
  })),
};

NavLinks.defaultProps = {
  footerLinks: [],
};

export default NavLinks;
