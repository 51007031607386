import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { logoHeaderSecondary as brandLogo } from '@edx/brand';

function LogoSecondary({ src, alt, ...attributes }) {
  return (
    <div className="logoSecondary">
      <img src={brandLogo} alt={alt} {...attributes} />
    </div>
  );
}

LogoSecondary.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LogoSecondary;
