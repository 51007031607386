import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { AppContext } from '@edx/frontend-platform/react';

import AnonymousUserMenu from './AnonymousUserMenu';
import AuthenticatedUserDropdown from './AuthenticatedUserDropdown';
import messages from './messages';
import { logoHeader as brandLogo } from '@edx/brand';
import { logoHeaderSecondary as brandLogoSecondary } from '@edx/brand';

function LinkedLogo({
  href,
  alt,
  ...attributes
}) {
  return (
    <a href={href} {...attributes}>
      <img className="d-block" src={brandLogo} alt={alt} />
    </a>
  );
}

function LogoSecondary({
  alt,
  ...attributes
}) {
  return (
    <div {...attributes}>
      <img className="d-block" src={brandLogoSecondary} alt={alt} />
    </div>
  );
}

LinkedLogo.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

LogoSecondary.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

function LearningHeader({
  intl, showUserDropdown,
}) {
  const { authenticatedUser } = useContext(AppContext);

  const headerLogo = (
    <LinkedLogo
      className="logo"
      href={`${getConfig().LMS_BASE_URL}/dashboard`}
      src={brandLogo}
      alt={getConfig().SITE_NAME}
    />
  );

  const headerLogoSecondary = (
    <LogoSecondary
      className="logoSecondary"
      src={brandLogoSecondary}
      alt={getConfig().SITE_NAME}
    />
  );

  return (
    <header className="learning-header">
      <a className="sr-only sr-only-focusable" href="#main-content">{intl.formatMessage(messages.skipNavLink)}</a>
      <div className="learning-header-content container-fluid d-flex align-items-center">
        {headerLogo}
        <div className="learning-header-secondary-holder">
          {showUserDropdown && authenticatedUser && (
            <AuthenticatedUserDropdown
              username={authenticatedUser.username}
            />
          )}
          {showUserDropdown && !authenticatedUser && (
            <AnonymousUserMenu />
          )}
          {headerLogoSecondary}
        </div>
      </div>
    </header>
  );
}

LearningHeader.propTypes = {
  intl: intlShape.isRequired,
  showUserDropdown: PropTypes.bool,
};

LearningHeader.defaultProps = {
  showUserDropdown: true,
};

export default injectIntl(LearningHeader);
