import React from 'react';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { AppContext } from '@edx/frontend-platform/react';
import { getConfig } from '@edx/frontend-platform';

// eslint-disable-next-line import/no-unresolved
import { footerLogo, isFooterDark } from '@edx/brand';
import messages from './Footer.messages';
import NavLinks from './NavLinks';

class SiteFooter extends React.PureComponent {
  render() {
    const { intl } = this.props;
    const footerLinks = [
      {
        href: 'https://forum.abinitio.com/static/legal/privacy_policy.html',
        title: intl.formatMessage(messages['footer.legalLinks.privacyPolicy']),
        target_blank: true,
      },
      {
        href: 'https://forum.abinitio.com/static/legal/terms_of_use.html',
        title: intl.formatMessage(messages['footer.legalLinks.termsOfUse']),
        target_blank: true,
      },
      {
        href: `${getConfig().LMS_BASE_URL}/honor`,
        title: intl.formatMessage(messages['footer.legalLinks.HonorCode']),
        target_blank: false,
      },
      {
        href: 'https://forum.abinitio.com/static/legal/trademarks.html',
        title: intl.formatMessage(messages['footer.legalLinks.intellectualProperty']),
        target_blank: true,
      },
    ];

    return (
      <footer
        role="contentinfo"
        className="footer"
      >
        <div className="holder">
          <div className="footer-holder">
            <div className="footer-logo">
              <a href={`${getConfig().LMS_BASE_URL}/`}>
                <img
                  src={footerLogo}
                  alt={intl.formatMessage(messages['footer.logo.altText'])}
                />
              </a>
            </div>
            <div className="footer-support">
              <a
                className="footer-support__edx"
                href="https://open.edx.org"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={intl.formatMessage(messages['footer.logo.ariaLabel'])}
              >
                <img
                  src={
                    isFooterDark
                      ? 'https://files.edx.org/openedx-logos/open-edx-logo-tag-dark.png'
                      : 'https://files.edx.org/openedx-logos/open-edx-logo-tag.png'
                  }
                  alt={intl.formatMessage(messages['footer.logo.altText'])}
                />
              </a>
            </div>
          </div>
          <p className="footer-text">
            &copy; {intl.formatMessage(messages['footer.copyright.text'])}
          </p>
          <NavLinks footerLinks={footerLinks} />
        </div>
      </footer>
    );
  }
}

SiteFooter.contextType = AppContext;

SiteFooter.propTypes = {
  intl: intlShape.isRequired,

};

export default injectIntl(SiteFooter);
