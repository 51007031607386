import { useState, useEffect } from 'react';
import { getConfig } from '@edx/frontend-platform';
import { getAuthenticatedHttpClient } from '@edx/frontend-platform/auth';

/*
 * A custom hook that returns whether current user has rights to view Progress Report page, i.e. should
 * the link to the page be shown in the header.
 */
export const useHasViewReportPermission = () => {
  const [canViewReport, setCanViewReport] = useState(false);

  useEffect(() => {
    const url = `${getConfig().LMS_BASE_URL}/api/abinitio/v1/has_view_report_permission/`;
    getAuthenticatedHttpClient().get(url).then(response => setCanViewReport(response.data.has_permission));
  }, []);

  return canViewReport;
};

export const useGetProfileImage = () => {
  const [userProfileImage, setUserProfileImage] = useState('');

  useEffect(() => {
    const url = `${getConfig().LMS_BASE_URL}/get_profile_image/`;
    getAuthenticatedHttpClient().get(url).then(response => setUserProfileImage(response.data.profile_image));
  }, []);

  return userProfileImage;
};

export const useHasBaseCurriculumPath = () => {
  const [canViewCurriculumPath, setCanViewCurriculumPath] = useState(false);

  useEffect(() => {
    const url = `${getConfig().LMS_BASE_URL}/has_base_curriculum_path/`;
    getAuthenticatedHttpClient().get(url).then(
      response => setCanViewCurriculumPath(response.data.can_show_curriculum_path),
    );
  }, []);

  return canViewCurriculumPath;
};
